import * as React from "react"
import Layout from "../components/layout"
import { Header } from "../components/organisms/Header"
import SEO from "../components/seo"
import waves from "../images/waves_picture.jpg";
import IndexNews from "../components/molecules/IndexNews";
import AllDataboutProducts, { DataboutProductInfo } from "../components/organisms/AllDataboutProducts";
import PageContent from "../components/organisms/PageContent";
import { graphql } from "gatsby";
import NewestProjects from "../components/organisms/NewestProjects";
import ClientsSlider from "../components/organisms/ClientsSlider";
import ScrollNavigation from "../components/atoms/ScrollNavigation";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { getPostModel, WpPost } from "../shared/wp-post-utils";
import { defaultLanguage } from "../../site-config";
import footprintImage from "../images/index/carbon-footprint-index.svg";
import enviromentImage from "../images/index/uwarunkowania-srodowiskowe-index.svg";
import uavImage from "../images/index/uav-index.svg";
import { addFormattedIndex, HasFormattedIndex } from "../lib/formattedIndex";

export type IndexPageProps = {
   data: { 
     newestProjects: { 
        nodes: WpPost[]
      },
      newestNews: { 
          nodes: WpPost[]
      }
    }
  }

export type SectionInfo = HasFormattedIndex & {
  name: string;
  localizableNavigationLabelKey: string;
}

const products: DataboutProductInfo[] = [{
  name: "carbonFootprint",
  link: "/uslugi/srodowisko/carbonfootprint/",
  imgUrl: footprintImage,
  localizableTitleKey: "indexContentTitle2",
  localizableDescriptionKey: "indexContentDescription2",
  localizableButtonLabelKey: "indexContentButtonTitle2",
  localizableNavigationLabelKey: "services.carbonFootprint",
  formattedIndex: null
}, {
  name: "environment",
  link: "/uslugi/srodowisko/",
  imgUrl: enviromentImage,
  localizableTitleKey: "indexContentTitle3",
  localizableDescriptionKey: "indexContentDescription3",
  localizableButtonLabelKey: "indexContentButtonTitle3",
  localizableNavigationLabelKey: "services.environment",
  formattedIndex: null
}, {
  name: "drones",
  link: "/uslugi/drony/",
  imgUrl: uavImage,
  localizableTitleKey: "indexContentTitle4",
  localizableDescriptionKey: "indexContentDescription4",
  localizableButtonLabelKey: "indexContentButtonTitle4",
  localizableNavigationLabelKey: "services.drones",
  formattedIndex: null
}];

const IndexPage: React.FC<IndexPageProps> = ({data}) => {
	const {t} = useTranslation();
  const {language} = useI18next();
	const projects = data.newestProjects.nodes.map(getPostModel);
  const news = data.newestNews.nodes.map(getPostModel);

  const sections: SectionInfo[] = [];
  let projectsSection: SectionInfo;
  let newsSection: SectionInfo;

  if (language === defaultLanguage) {
    projectsSection = {
      name: "projects",
      localizableNavigationLabelKey: "newestProjectsTitle",
      formattedIndex: null
    };
    sections.push(projectsSection);
  
    newsSection = {
      name: "news",
      localizableNavigationLabelKey: "newsTitle",
      formattedIndex: null
    };
    sections.push(newsSection);
  }

  const clientsSection: SectionInfo = {
    name: "clients",
    localizableNavigationLabelKey: "sliderTitle",
    formattedIndex: null
  };
  sections.push(clientsSection);

  const scrollNavigationItems = (products as SectionInfo[]).concat(sections);
  scrollNavigationItems.forEach(addFormattedIndex);

	return (
    <Layout>
      <SEO title="Databout - Locate it, Smart!" description={t('header.seoDescription')}/>
      
      <Header 
        title={t('header.title')}
        subTitle={t('header.subTitle')}
        textInDownPosition={true}
        description={t('header.description')}
        //buttonText={t('header.buttonText')}
        //buttonLink="https://tools.databout.pl/"
        backgroundImage={waves}
      />
      <PageContent>
        {newsSection && <IndexNews newestNews={news} sectionInfo={newsSection}/>}
        {projectsSection && <NewestProjects newestProjects={projects} sectionInfo={projectsSection}/>}
        <ClientsSlider sectionInfo={clientsSection}/>
      </PageContent>
    </Layout>
  )
}
export default IndexPage


export const pageQuery = graphql`
query($language: String!) {
  newestProjects: allWpPost(
    sort: {fields: dateGmt, order: DESC}
    filter: {categories: {nodes: {elemMatch: {name: {eq: "Projekt"}}}}, language: {slug: {eq: $language}}}
    limit: 3
  ) {
    nodes {
      ...PageItems
    }
  }
  newestNews: allWpPost(
    sort: {fields: dateGmt, order: DESC}
    filter: {categories: {nodes: {elemMatch: {name: {eq: "News"}}}}, language: {slug: {eq: $language}}}
    limit: 3
  ) {
      nodes {
        ...PageItems
    }
  }
  locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
}`

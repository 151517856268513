import * as React from 'react';
import styled from 'styled-components';
import ArrowLink from '../atoms/ArrowLink';
import { changeDate } from '../../ChangeDate';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Post } from '../../shared/wp-post-utils';
import Img from "gatsby-image";

export type NewestProjectItemType = {
    post: Post;
}

const StyledNewestProjectItem = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    width: 33.333%;
    padding: 0 47px;
    margin: 0;

    .newestProject__image {
        border-radius: 8px;
        margin-bottom: 24px;
        max-width: 100%;
        height: auto;
        max-height: 230px;
    }

    @media (max-width: 767.98px) {
        padding: 0 16px;
        width: 80%;
        .newestProject__image {
            max-height: 99px;
        }
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        padding: 0 16px;
        width: 55%;
        .newestProject__image {
            max-height: 110px;
        }
    }

    @media (min-width: 992px) and (max-width: 1199.98px) { 
        padding: 0 32px;
        width: 50%;
        .newestProject__image {
            max-height: 131px;
        }
    }

    @media (min-width: 1200px) and (max-width: 1600px) {
        padding: 0 32px;
    }
`

const ItemDate = styled.span`
    margin-bottom: 24px;
    font-family: Museo;
    font-weight: 300;
    font-size: 16px;
    line-height: 156%;
`

const ItemTitle = styled.span`
    font-family: Museo;
    font-weight: normal;
    font-size: 22px;
    line-height: 125%;
`

const ItemDescription = styled.p`
    
`

const NewestProjectItem: React.FC<NewestProjectItemType> = ({post: { image, date, title, url, summary }}) => {
    const {t} = useTranslation();
    
    return ( 
        <StyledNewestProjectItem>
            <Img className="newestProject__image" fixed={image} alt={title} />
            {/*<ItemDate>{changeDate(date)}</ItemDate>*/}
            <ItemTitle>{title}</ItemTitle>
            <ItemDescription>{summary}</ItemDescription>
            <ArrowLink textWidth={200} link={url}>{t('readMore')}</ArrowLink>
        </StyledNewestProjectItem>
     );
}

export default NewestProjectItem;

import * as React from 'react';
import styled from 'styled-components';
import ArrowLink from '../atoms/ArrowLink';
import { PostEnumeration } from '../atoms/PostEnumeration';
import { changeDate } from '../../ChangeDate';
import { Custom } from './DataboutProduct';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Post } from '../../shared/wp-post-utils';
import { SectionInfo } from '../../pages/index';

export interface IndexNewsProps {
    newestNews: Post[];
    sectionInfo: SectionInfo;
}

const Content = styled.div<Custom>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 95%;
    padding: ${({ theme }) => theme.paddingDesktop}; 
    padding-top: 80px;
    padding-bottom: 80px;

    @media (min-width: 992px) and (max-width: 1600px) {
        padding: ${({ theme }) => theme.paddingLaptop}; 
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        padding: ${({ theme }) => theme.paddingTablet};
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        padding: 0 0 ${({ theme }) => theme.paddingPhone}; 
        width: 100%;
        padding-top: 40px;
        padding-bottom: 40px;
     }
`;

const Row = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 52px;

    @media (min-width: 1px) and (max-width: 767.98px) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 30px;
     }
`;

export const TitleContent = styled.div`
    display: flex;
    align-items: flex-end;

    @media (min-width: 992px) and (max-width: 1600px) {
        flex-direction: column;
        align-items: flex-start;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        flex-direction: column;
        align-items: flex-start;
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        flex-direction: column;
        align-items: flex-start;
     }
`;

export const Title = styled.h2`
    color: ${({ theme }) => theme.primaryColor};
    font-size: ${({ theme }) => theme.fontSize.h1}; 
    margin: 0;
    line-height: 0.8;

    @media (min-width: 992px) and (max-width: 1600px) {
        margin: 16px 0 0 0;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        margin: 16px 0 0 0;
        font-size: 40px;
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        margin: 16px 0;
        line-height: 1.3;
        font-size: 40px;
    }
`;

const NewsBox = styled.div`
    display: flex;
    justify-content: space-between;
    width: 94%;
    height: 295px;
    background-color: #fff;
    box-shadow: ${({ theme }) => theme.boxShadow}; 
    border-radius: 16px;
    padding: 55px 41px;

    @media (min-width: 992px) and (max-width: 1600px) {
        width: 100%;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        width: 100%;
        padding: 35px 35px;
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        width: 100%;
        background-color: transparent;
        box-shadow: none;
        padding: 25px 10px;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        div:nth-last-of-type(1) {
            margin-right: 12px;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-basis: 30%;

    @media (min-width: 1px) and (max-width: 767.98px) {
        background-color: #fff;
        box-shadow: ${({ theme }) => theme.boxShadow}; 
        border-radius: 16px;
        padding: 32px 20px;
        margin-right: 34px;
        min-width: 85%;
    }
`;

const DescriptionContent = styled.p`
    flex-basis: 90%;
    display: flex;
    align-items: flex-start;
    font-weight: 400;
    margin: 0 0 14px;
    font-family: Museo;
    white-space: normal;
    font-size: 22px;

    @media (min-width: 992px) and (max-width: 1199.98px) { 
        font-size: 18px;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        font-size: 17px;
    }

    @media (min-width: 321px) and (max-width: 767.98px) {
        font-size: 19px;
    }

    @media (min-width: 1px) and (max-width: 320.98px) {
        font-size: 17px;
    }
`;

const Date = styled.p`
    font-size: 16px;
    margin: 0 0 24px;
    font-family: Museo;
`;


const IndexNews: React.FC<IndexNewsProps> = ({sectionInfo: {name, formattedIndex}, newestNews}) => {
	const {t} = useTranslation();
    
    return ( 
        <Content name={name}>
            <Row>
                <TitleContent>
                    {/*<PostEnumeration formattedIndex={formattedIndex}/>*/}
                    <Title>{t('newsTitle')}</Title>
                </TitleContent>
                <ArrowLink textWidth={220} link="/aktualnosci">{t('seeAll')}</ArrowLink>
            </Row>
            <NewsBox> 
                {
                    newestNews.map(post => 
                        <Card key={post.url}>
                            <Date>{changeDate(post.date)}</Date>
                            <DescriptionContent>{post.title}</DescriptionContent>
                            <div>
                                <ArrowLink textWidth={200} link={post.url}>{t('readMore')}</ArrowLink>
                            </div>
                        </Card>)
                }
            </NewsBox>
        </Content>
     );
}
 
export default IndexNews;

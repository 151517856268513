import React from "react";
import styled from "styled-components";
import ArrowRight from "../../images/icon-arrow-right.svg";
import ArrowLeft from "../../images/icon-arrow-left.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ClientsCarouselItem from "../atoms/ClientsCarouselItem";
import ClientsCarouselArrow from "../atoms/ClientsSliderArrow";
import { Title, TitleContent } from "../molecules/IndexNews";
import { PostEnumeration } from "../atoms/PostEnumeration";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { SectionInfo } from "../../pages/index";

//////////////////// React Slick Slider //////////////////

const ClientsCarouselWrapper = styled.div<{name?: string}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: ${({ theme }) => theme.paddingDesktop};
  padding-top: 130px;
  padding-bottom: 200px;

  @media (max-width: 767.98px) {
    padding: ${({ theme }) => theme.paddingPhone};
    padding-bottom: 80px;
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    padding: ${({ theme }) => theme.paddingTablet};
    padding-bottom: 120px;
    padding-top: 80px;
  }

  @media (min-width: 992px) and (max-width: 1600px) {
    padding: ${({ theme }) => theme.paddingLaptop};
    padding-bottom: 120px;
    padding-top: 80px;
  }
`;

const StyledTitleContent = styled(TitleContent)`
  padding-bottom: 178px;

  @media (min-width: 992px) and (max-width: 1600px) {
    padding-bottom: 152px;
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    padding-bottom: 152px;
  }

  @media (min-width: 1px) and (max-width: 767.98px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const CarouselItems = [
  {
    src: (
      <StaticImage
        src="../../images/clients/client-gddkia.png"
        alt="GDDKiA"
        placeholder="tracedSVG"
        quality={100}
        height={100}
      />
    ),
  },
  {
    src: (
      <StaticImage
        src="../../images/clients/client-lamela.png"
        alt="Estudio Lamela"
        placeholder="tracedSVG"
        height={100}
        quality={100}
      />
    ),
  },
  {
    src: (
      <StaticImage
        src="../../images/clients/client-mielec.png"
        alt="Lotnisko Mielec"
        placeholder="tracedSVG"
        quality={100}
        height={100}
      />
    ),
  },
  {
    src: (
      <StaticImage
        src="../../images/clients/client-plk.png"
        alt="PLK"
        placeholder="tracedSVG"
        quality={100}
        height={100}
      />
    ),
  },
  {
    src: (
      <StaticImage
        src="../../images/clients/client-warszawa.png"
        alt="Miasto Stołeczne Warszawa"
        placeholder="tracedSVG"
        quality={100}
        height={100}
      />
    ),
  },
  {
    src: (
      <StaticImage
        src="../../images/clients/client-wood.png"
        alt="Wood Group"
        placeholder="tracedSVG"
        quality={100}
        height={100}
      />
    ),
  },
  {
    src: (
      <StaticImage
        src="../../images/clients/client-cbre.png"
        alt="CBRE"
        placeholder="tracedSVG"
        quality={100}
        height={100}
      />
    ),
  },
  {
    src: (
      <StaticImage
        src="../../images/clients/client-cpk.png"
        alt="Centralny Port Komunikacyjny"
        placeholder="tracedSVG"
        quality={100}
        height={100}
      />
    ),
  },
  {
    src: (
      <StaticImage
        src="../../images/clients/client-echo.png"
        alt="Echo Investment"
        placeholder="tracedSVG"
        quality={100}
        height={100}
      />
    ),
  },
  {
    src: (
      <StaticImage
        src="../../images/clients/client-gdansk.png"
        alt="Port Gdańsk"
        placeholder="tracedSVG"
        quality={100}
        height={100}
      />
    ),
  },
  {
    src: (
      <StaticImage
        src="../../images/clients/client-gdynia.png"
        alt="Port Gdynia"
        placeholder="tracedSVG"
        quality={100}
        height={100}
      />
    ),
  },
  {
    src: (
      <StaticImage
        src="../../images/clients/client-jacobs.png"
        alt="Jacobs"
        placeholder="tracedSVG"
        quality={100}
        height={100}
      />
    ),
  },
  {
    src: (
      <StaticImage
        src="../../images/clients/client-bnp-paribas.png"
        alt="BNP Paribas"
        placeholder="tracedSVG"
        quality={100}
        height={100}
      />
    ),
  },
];

type ClientsCarouselProps = {
  sectionInfo: SectionInfo;
}

const ClientsCarousel: React.FC<ClientsCarouselProps> = ({ sectionInfo: {name, formattedIndex} }) => {
  const {t} = useTranslation();
  
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 3000,
    initialSlide: 0,
    pauseOnHover: false,
    draggable: false,
    nextArrow: <ClientsCarouselArrow src={ArrowRight} next />,
    prevArrow: <ClientsCarouselArrow src={ArrowLeft} prev />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <>
      <ClientsCarouselWrapper className="clientsCarousel" name={name}>
        <StyledTitleContent>
          {/*<PostEnumeration formattedIndex={formattedIndex}/>*/}
          <Title>{t('sliderTitle')}</Title>
        </StyledTitleContent>
        <Slider {...settings}>
          {CarouselItems.map((item, i) => (
            <ClientsCarouselItem src={item.src as any as string} key={i} />
          ))}
        </Slider>
      </ClientsCarouselWrapper>
    </>
  );
};

export default ClientsCarousel;

import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

const StyledClientsCarouselArrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ prev }) =>
    prev &&
    css`
      margin-right: 30px;
    `}

  ${({ next }) =>
    next &&
    css`
      margin-left: 30px;
    `}
`;

const ClientsCarouselArrowImage = styled.div`
  width: auto;
  max-height: 120px;
  max-width: 230px;
  cursor: pointer;

  @media (max-width: 767.98px) {
    max-height: 100px;
    max-width: 150px;
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    max-height: 120px;
    max-width: 200px;
  }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    max-height: 120px;
    max-width: 130px;
  }
  @media (min-width: 1200px) and (max-width: 1600px) {
    max-height: 120px;
    max-width: 170px;
  }
`;

const ClientsCarouselArrow = ({ src, onClick = undefined, prev = undefined, next = undefined }) => (
  <StyledClientsCarouselArrow prev={prev} next={next}>
    <ClientsCarouselArrowImage>{src}</ClientsCarouselArrowImage>
  </StyledClientsCarouselArrow>
);

ClientsCarouselArrow.propTypes = {
  type: PropTypes.string,
  src: PropTypes.string,
};

export default ClientsCarouselArrow;

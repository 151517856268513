import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import styled from 'styled-components';
import { SectionInfo } from '../../pages/index';
import { Post } from '../../shared/wp-post-utils';
import ArrowLink from '../atoms/ArrowLink';
import { PostEnumeration } from '../atoms/PostEnumeration';
import { Custom } from '../molecules/DataboutProduct';
import { Title, TitleContent } from '../molecules/IndexNews';
import NewestProjectItem from '../molecules/NewestProjectItem';

type NewestProjectsProps = {
    newestProjects: Post[];
    sectionInfo: SectionInfo;
}

const StyledNewestProjects = styled.div<Custom>`
    padding: ${({ theme }) => theme.paddingDesktop}; 
    padding-top: 120px;
    padding-bottom: 150px;

    @media (max-width: 767.98px) {
        padding: ${({ theme }) => theme.paddingPhone}; 
        padding-top: 40px;
        padding-bottom: 40px;
        height: auto;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        padding: ${({ theme }) => theme.paddingTablet}; 
        padding-top: 80px;
        padding-bottom: 80px;
        height: auto;
    }

    @media (min-width: 992px) and (max-width: 1199.98px) { 
        padding: ${({ theme }) => theme.paddingLaptop}; 
        padding-top: 80px;
        padding-bottom: 80px;
        height: auto;
    }

    @media (min-width: 1200px) and (max-width: 1600px) {
        padding: ${({ theme }) => theme.paddingLaptop}; 
        padding-top: 80px;
        padding-bottom: 80px;
        height: auto;
    }
`

const NewestProjectsWrapper = styled.div`
    display: flex;
    padding: 0 80px; 
    margin: 0 -47px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }

    @media (max-width: 991.98px) {
        padding: 0;
        margin: 0 -16px;
        overflow-y: scroll;
    }

    @media (min-width: 992px) and (max-width: 1199.98px) { 
        padding: 0;
        margin: 0 -32px;
    }

    @media (min-width: 1200px) and (max-width: 1600px) {
        padding: ${({ theme }) => theme.paddingLaptop}; 
        margin: 0 -32px;
        padding: 0;
    }
`
const Row = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 52px;
    padding-right: 120px;

    @media (min-width: 1200px) and (max-width: 1600px) {
        padding-right: 90px;
    }

    @media (min-width: 992px) and (max-width: 1199.98px) { 
         padding-right: 0px;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        padding-right: 0px;
    }


    @media (min-width: 1px) and (max-width: 767.98px) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 30px;
     }
`;

const NewestProjects: React.FC<NewestProjectsProps> = ({ sectionInfo: {name, formattedIndex}, newestProjects }) => {
	const {t} = useTranslation();
    return ( 
        <StyledNewestProjects name={name}>
            <Row>
                <TitleContent>
                    {/*<PostEnumeration formattedIndex={formattedIndex}/>*/}
                    <Title>{t('newestProjectsTitle')}</Title>
                </TitleContent>
                <ArrowLink textWidth={220} link="/aktualnosci">{t('seeAll')}</ArrowLink>
            </Row>
            <NewestProjectsWrapper>
             {newestProjects.map((post, index) => (
                <NewestProjectItem key={index} post={post}/>)
            )}
            </NewestProjectsWrapper>
        </StyledNewestProjects>
     );
}


export default NewestProjects;


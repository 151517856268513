import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

const StyledClientsCarouselArrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ prev }) =>
    prev &&
    css`
      margin-right: 30px;
    `}

  ${({ next }) =>
    next &&
    css`
      margin-left: 30px;
    `}
`;

const ClientsCarouselArrowImage = styled.img`
  display: none;
  width: auto;
  max-height: 40px;
  cursor: pointer;

  @media (max-width: 767.98px) {
    max-height: 25px;
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    max-height: 30px;
  }
  @media (min-width: 992px) and (max-width: 1600px) {
    max-height: 35px;
  }
`;

const ClientsCarouselArrow = ({ src, onClick = undefined, prev = undefined, next = undefined }) => (
  <StyledClientsCarouselArrow prev={prev} next={next}>
    <ClientsCarouselArrowImage alt="carousel nav" src={src} onClick={onClick} />
  </StyledClientsCarouselArrow>
);

ClientsCarouselArrow.propTypes = {
  type: PropTypes.string,
  src: PropTypes.string,
};

export default ClientsCarouselArrow;
